import React, { useState } from "react"
import { Col, Container, Row } from 'react-bootstrap'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Title, FreshChatComponent } from 'gatsby-theme-oneway-ui-components';
import { isBrowser } from 'gatsby-theme-oneway-httprequest';
import OpenChatButton from '../components/openChat'
import { useStaticQuery, graphql } from 'gatsby';

const NotFoundPage = () => {
  const [openChat, setOpenChat] = useState(false);

  const data = useStaticQuery(
		graphql`
			query {
        file(relativePath: {eq: "logo-oneway.png"}) {
          childImageSharp {
            gatsbyImageData(
							placeholder: BLURRED
							transformOptions: { cropFocus: CENTER }
							layout: CONSTRAINED
              width: 312
						)
          }
        }
			}
		`
	)

  const handleOpenChat = () => {
		setOpenChat(true);

		if (openChat) {
			isBrowser && window.fcWidget.open();
		}
	};

  const logo = getImage(data.file.childImageSharp.gatsbyImageData)

  const chatSupport = openChat && <FreshChatComponent openChat={openChat} />;

  return (
    <>
      {chatSupport}
      <main className='py-5' aria-labelledby='development' style={{ minHeight: '85vh' }}>
        <Container className='pt-5'>
          <Col className='px-0'>
            <Row className='mx-0'>
              <GatsbyImage image={logo} alt='Onewayecommerce Logo' className='mx-auto' />
            </Row>
            <Col className='px-0 pt-5 text-center'>
              <Title as='h1' id='development' title='Error 404!' color='dark' customClass='pb-3' />
              <p className='text-onewayecommerce-style'>Sorry, your search returns no results, but let us help you.</p>
            </Col>
            <OpenChatButton title='CHAT WITH US' clickAction={handleOpenChat} />
          </Col>
        </Container>
      </main>
      <footer className='pt-5' style={{ minHeight: '15vh' }}>
        <Container className='text-center'>
          <Title as='h5' title='Copyright © 2020. All rights reserved.' color='dark' />
        </Container>
      </footer>
    </>
  )
}

export default NotFoundPage
